<template>
    <div class="intro">
        <p>
            Ceci est la Grand'Place des Guinzes, le projet central dont l'Agenda 
            des Guinzes est désormais issu. Cependant, je vais traiter un projet 
            à la fois, et le plus important d'abord : l'Agenda des Guinzes, avec  son interface de 
            Chancellerie, et celle de modération.
        </p>
    </div>
</template>

<script>
export default {
    name: 'Intro'
}
</script>

<style scoped>
.intro {
        width: 100vw;
    max-width: 512px;
    min-width: 320px;

    padding: 0 10px 0 5px;

    text-align: justify;
}
</style>